<template>
  <div class="header-top">
    <router-link to="/index"><div class="logo"></div></router-link>
    <div class="head">
        <router-link class="a" active-class="active" to="/feedback">意见反馈</router-link>
        <router-link class="a" active-class="active" to="/aboutus">关于我们</router-link>
        <router-link class="a" active-class="active" to="/news">新闻动态</router-link>
        <div class="a" :class="{active:dropSelect!=''}">
          <el-dropdown  @command="handleCommand" placement="bottom">
              <div class="tabItem">
                  产品中心<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item class="dropDownItem" :class="{activeText:dropSelect=='/pmm'}" command="/pmm">金先生供销</el-dropdown-item>
                <el-dropdown-item class="dropDownItem b_t" :class="{activeText:dropSelect=='/jxs'}" command="/jxs">金先生优选</el-dropdown-item>
                  <el-dropdown-item class="dropDownItem b_t" :class="{activeText:dropSelect=='/scb'}" command="/scb">金先生素材宝</el-dropdown-item>
                  <el-dropdown-item class="dropDownItem b_t" :class="{activeText:dropSelect=='/serves'}" command="/serves">企坤</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <router-link class="a" active-class="active" to="/serves">产品中心</router-link> -->
        <router-link class="a" active-class="active" to="/index">首页</router-link>		    
    </div>
	</div>
</template>

<script>
export default {
  data(){
    return {
      dropSelect:''
    }
  },
  methods:{
    handleCommand(command) {
      this.dropSelect = command
      this.$router.replace(command)
    }
  },
  watch:{
    $route(to){
      // console.log('/'+to.path.split('/')[1])
      if((to.path!='/scb')&&(to.path!='/pmm')&&(to.path!='/jxs')&&(String(to.path).indexOf('/serves')==-1)){
        this.dropSelect = ''
      } else {
        this.dropSelect = '/'+to.path.split('/')[1]
      }
    }
  }
}
</script>
<style>
.el-dropdown-menu{
  width: 220px;
}
.dropDownItem{
  width: 150px!important;
  margin: 0 auto!important;
}
</style>
<style scoped>

.header-top{ 
    position: absolute;
    width: 100%;
    height: 78px;
    background: #0B25B1;
    position: fixed;
    z-index: 999;
    min-width: 1200px;
    border-bottom:rgba(255, 255, 255,0.2)  1px  solid;
}
.tabItem {
  height: 40px;
}
.dropDownItem {
  text-align: center;
  width: 70px;
  margin: 0px 25px;
}
.el-dropdown {
  color: #FFFFFF;
  font-size: 16px;
}
.el-dropdown-menu__item:hover {
  background: #FFFFFF;
}
.b_t {
  border-top: 1px #EDEDED solid;
}
.head{    
    float: right;
    margin-right: 19%;   
}
.a{
     display: block;
    float:right;
    text-decoration: none;
    width: auto;
    height: 45px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
     margin-top: 32px;
     margin-left:52px ;
}
.logo{
    margin-top: 17px;
    margin-left:19%;
    float: left;
    width: 186px;
    height: 38px;
    background-image: url(https://oa-zhcx.oss-cn-hangzhou.aliyuncs.com/website/images/images/logo.png);
    background-repeat: no-repeat;
    background-size: contain ;
}
.activeText {
  color: #2055E7;
}
.active ,
.a:hover{
	border-bottom:2px #FFFFFF solid;
}

</style>